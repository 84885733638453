<template>
  <div>
    <div style="margin-top: 2px">
      <v-card tile flat class="pa-2 pl-8 pr-8">
        <div class="d-flex align-center flex-wrap justify-space-between">
          <!-- <v-btn
            color="primary"
            small
            dark
            class="ml-3 border-radius-6"
            @click="redirectToArchive"
          >
            Archive
          </v-btn> -->
          <div class="d-flex align-center" style="gap: 10px">
            <span> <b> Project Statistics </b> </span>
            <v-divider
              :thickness="5"
              class="border-opacity-90"
              :vertical="true"
            ></v-divider>
            <span class="span_header"
              >Total Projects: <b> {{ projects.length || 0 }} </b>
            </span>
            <v-divider
              :thickness="5"
              class="border-opacity-90"
              :vertical="true"
            ></v-divider>
            <span class="span_header"
              >Completed Project:
              <b style="color: green"> {{ completedProjects }} </b>
            </span>
            <v-divider
              :thickness="5"
              class="border-opacity-90"
              :vertical="true"
            ></v-divider>
            <span class="span_header"
              >In Progress Project :
              <b style="color: #fea246">{{ inProgressProjects }}</b>
            </span>
            <v-divider
              :thickness="5"
              class="border-opacity-90"
              :vertical="true"
            ></v-divider>
            <span class="span_header"
              >On Hold : <b style="color: #faa183"> {{ onHoldProjects }} </b>
            </span>
            <v-divider
              :thickness="5"
              class="border-opacity-90"
              :vertical="true"
            ></v-divider>
            <span class="span_header"
              >Cancel : <b class="red--text"> {{ cancelProjects }}</b>
            </span>
          </div>
          <div class="d-flex align-center">
            <v-text-field
              v-model.lazy="search"
              prepend-inner-icon="mdi-magnify"
              :append-icon="search ? 'mdi-close' : ''"
              @click:append="clearSearch"
              label="Search"
              single-line
              hide-details
              dense
              outlined
              class="mr-4 border-radius-6"
              :style="{ maxWidth: '300px' }"
              v-debounce="500"
            ></v-text-field>
            <v-btn
              color="primary"
              dark
              class="px-3 border-radius-6 custom-data-table-action-btn"
              @click="openAddProjectDialog"
            >
              <v-icon class="mr-1"> mdi-plus </v-icon>
              Add
            </v-btn>
          </div>
        </div>
      </v-card>
    </div>
    <div class="mt-2 px-0">
      <v-card tile class="pa-3 pt-8" flat>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="filteredItems"
          :search="search"
          :items-per-page="15"
          :page.sync="currentPage"
          class="custom-data-table mx-4"
        >
          <!-- project name with color -->
          <template v-slot:[`item.name`]="{ item }">
            <div @click="redirect(item.project_id)" style="cursor: pointer">
              <Avatar
                :size="'30px'"
                :color="item.project_color_hex || 'blue lighten-3'"
                :intials="item.name ? item.name.charAt(0).toUpperCase() : 'P'"
                class="mr-2 my-2"
              />
              {{ item.name }}
            </div>
          </template>

          <template v-slot:[`item.assignee`]="{ item }">
            <div class="d-flex align-center">
              <template v-for="(assignee, i) in item.team.projectTeams">
                <div class="team-name-avatars" :key="assignee.id" v-if="i < 4">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="asssignee-container mr-1"
                        v-if="assignee.employee"
                      >
                        <v-avatar
                          :color="assignee.employee.color"
                          class="white--text avatar-icon cursor-pointer mt-2"
                          size="35"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <img
                            v-if="assignee.employee.profile_url"
                            :src="assignee.employee.profile_url"
                            width="22"
                          />
                          <span v-else class="fontsize-11">{{
                            assignee.employee.intials
                          }}</span>
                        </v-avatar>
                      </div>
                    </template>
                    <span>{{
                      assignee.employee ? assignee.employee.name : ""
                    }}</span>
                  </v-tooltip>
                </div>
                <div
                  class="team-name-avatars"
                  :key="assignee.id"
                  v-else-if="i == 4"
                >
                  <v-menu
                    offset-y
                    open-on-hover
                    transition="scale-transition"
                    origin="top right"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="asssignee-container mr-1"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-avatar
                          color="primary"
                          class="white--text avatar-icon cursor-pointer mt-2"
                          size="35"
                        >
                          <span class="fontsize-11"
                            >+{{ item.team?.projectTeams?.length - 4 }}</span
                          >
                        </v-avatar>
                      </div>
                    </template>
                    <ul class="more-assignees-menu">
                      <template
                        v-for="(moreAssignee, j) in item.team.projectTeams"
                      >
                        <li v-if="j > 3" :key="j">
                          <div class="asssignee-container mr-1">
                            <v-avatar
                              color="primary"
                              class="white--text avatar-icon cursor-pointer"
                              size="35px"
                            >
                              <img
                                v-if="moreAssignee.employee.profile_url"
                                :src="moreAssignee.employee.profile_url"
                                width="22"
                              />
                              <span v-else class="fontsize-11">{{
                                moreAssignee.employee.intials
                              }}</span>
                            </v-avatar>
                          </div>
                          <span>{{ moreAssignee.employee.name }}</span>
                        </li>
                      </template>
                    </ul>
                  </v-menu>
                </div>
              </template>
            </div>
          </template>

          <!-- start date -->
          <template v-slot:[`item.start_date`]="{ item }">
            {{ convertDateFormat(item.start_date) }}
          </template>

          <!-- start date -->
          <template v-slot:[`item.end_date`]="{ item }">
            {{ convertDateFormat(item.end_date) }}
          </template>

          <!-- start date -->
          <template v-slot:[`item.project_status`]="{ item }">
            <!-- <v-chip
              label
              :style="{
                backgroundColor: `rgba(${parseInt(
                  getStatusColor(item.projectstatus.name).text.slice(1, 3),
                  16
                )}, ${parseInt(
                  getStatusColor(item.projectstatus.name).text.slice(3, 5),
                  16
                )}, ${parseInt(
                  getStatusColor(item.projectstatus.name).text.slice(5),
                  16
                )}, 0.2)`,
              }"
            >
              <span
                :style="{ color: getStatusColor(item.projectstatus.name).text }"
              >
                {{ item.projectstatus.name }}
              </span>
            </v-chip> -->
            <v-menu
              offset-y
              :close-on-content-click="true"
              v-model="menu[item.id]"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  label
                  v-bind="attrs"
                  v-on="on"
                  :style="{
                    backgroundColor: `rgba(${parseInt(
                      getStatusColor(item.projectstatus.name).text.slice(1, 3),
                      16
                    )}, ${parseInt(
                      getStatusColor(item.projectstatus.name).text.slice(3, 5),
                      16
                    )}, ${parseInt(
                      getStatusColor(item.projectstatus.name).text.slice(5),
                      16
                    )}, 0.2)`,
                  }"
                >
                  <span
                    :style="{
                      color: getStatusColor(item.projectstatus.name).text,
                    }"
                  >
                    {{ item.projectstatus.name }}
                  </span>
                  <v-icon
                    class="mdi mdi-menu-down"
                    :color="getStatusColor(item.projectstatus.name).text"
                    right
                  ></v-icon>
                </v-chip>
              </template>

              <v-list>
                <v-list-item
                  v-for="(status, index) in project_status"
                  :key="index"
                  @click="editProjectStatus(item, status)"
                >
                  <v-list-item-title>{{ status.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:[`item.priority`]="{ item }">
            <v-chip
              label
              :style="{
                backgroundColor: getStatus(item.start_date, item.end_date)
                  .color,
              }"
            >
              <v-icon color="white" style="font-weight: 400" left>
                mdi-flag-variant-outline</v-icon
              >
              <span style="color: white; font-weight: 500">
                {{ getStatus(item.start_date, item.end_date).name }}
              </span>
            </v-chip>
          </template>

          <!-- Active -->
          <template v-slot:[`item.is_active`]="{ item }">
            <v-switch
              v-model="item.is_active"
              @change="enableProject(item.project_id)"
              color="success"
              inset
            ></v-switch>
          </template>

          <!-- action links -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  @click="redirect(item.project_id)"
                  class="cursor-pointer mr-3"
                  v-bind="attrs"
                  v-on="on"
                >
                  <EyeIcon :size="20" />
                </span>
              </template>
              <span>View Board</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  @click.stop="
                    editItem(item);
                    form_title = 'Edit Project';
                    form_type_insert = false;
                  "
                  class="cursor-pointer mr-3"
                  v-bind="attrs"
                  v-on="on"
                >
                  <PencilIcon :size="19" />
                </span>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  @click.stop="deleteItem(item)"
                  class="cursor-pointer"
                  v-bind="attrs"
                  v-on="on"
                >
                  <TrashIcon :size="19" />
                </span>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </template>
        </v-data-table>

        <v-dialog v-model="dialogDelete" max-width="450px">
          <v-card>
            <v-card-title class="headline">Alert</v-card-title>
            <v-card-title class="subtitle-1 mb-4"
              >Are you sure you want to Delete this Project ?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="red darken-1" text @click="deleteItemConfirm"
                >Delete</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>

      <Dialog
        :isOpen="editDialog"
        @close="editProjectClose"
        title="Edit Project"
        :action="true"
        :save="editProject"
      >
        <template>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                label="Name"
                name="name"
                v-model="item.name"
                :rules="validation.nameRules"
                dense
                required
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="item.start_date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="projectAddStartDateFormat"
                    label="Start Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="item.start_date"
                  :format="'DD-MM-YYYY'"
                  @input="updateDate"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu2 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu2.save(item.start_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-menu
                ref="menu3"
                v-model="menu3"
                :close-on-content-click="false"
                :return-value.sync="item.end_date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="projectAddEndDateFormat"
                    label="End Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="item.end_date"
                  :format="'DD-MM-YYYY'"
                  @input="updateEndDate"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu3 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu3.save(item.end_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-autocomplete
                v-model="item.projectStatus"
                :items="project_status"
                auto-select-first
                label="Project Status"
                item-text="name"
                item-value="id"
                deletable-chips
                class="mt-0 pt-0"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" class="d-flex align-start justify-space-between">
              <v-switch
                class="mt-0 pa-0"
                v-model="enableEstimatedHours"
                label="Enable Estimated Hours"
                color="success"
                inset
              ></v-switch>
              <span class="span_text" style="color: #878dab"
                >Hours : {{ total_hours || "00" }}</span
              >
            </v-col>
            <template v-if="enableEstimatedHours">
              <v-col cols="12" v-for="(h, index) in hours_list" :key="index">
                <v-row no-gutters align="center" style="gap: 10px">
                  <v-text-field
                    v-model="hours_list[index].name"
                    dense
                    hide-details
                    outlined
                    style="flex: 1"
                  ></v-text-field>
                  <v-text-field
                    v-model="hours_list[index].hours"
                    dense
                    label="Hours"
                    type="number"
                    outlined
                    hide-details
                    style="flex: 0.5"
                  ></v-text-field>
                  <v-btn
                    icon
                    @click="addField"
                    v-if="hours_list.length - 1 === index"
                  >
                    <v-icon color="primary" size="24"
                      >mdi mdi-plus-circle-outline</v-icon
                    >
                  </v-btn>
                  <v-btn v-else icon @click="removeField(index)">
                    <TrashIcon :size="24" />
                  </v-btn>
                </v-row>
              </v-col>
            </template>
          </v-row>
          <v-row>
            <v-col>
              <p class="mb-2">Please choose a color</p>
              <v-swatches
                swatch-size="30"
                v-model="item.project_color_hex"
                inline
              >
              </v-swatches>
              <p>
                <input
                  v-model="item.project_color_hex"
                  type="color"
                  class="fallback-input"
                  :style="{ backgroundColor: item.project_color_hex }"
                />
              </p>
            </v-col>
          </v-row>
        </template>
      </Dialog>

      <v-snackbar
        v-model="snackbar"
        :timeout="3000"
        color="green accent-4"
        align-baseline="center"
      >
        {{ message }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar
        v-model="snackbarError"
        :timeout="3000"
        color="deep-orange accent-4"
        align-baseline="center"
      >
        {{ message }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbarError = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <Dialog
        :isOpen="dialog"
        :footer_class="'flex-column'"
        :action="false"
        :title="dialogTitle"
        @close="createProjectClose"
      >
        <template>
          <div class="full-width mt-6 mb-3">
            <StepperIndicator :steps="3" :currentStep="item.step" />
          </div>
        </template>

        <template>
          <v-card flat v-if="item.step == 1">
            <v-form ref="step1Form" v-model="step1Valid" lazy-validation>
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    label="Project Name"
                    v-model="item.name"
                    :rules="validation.nameRules"
                    dense
                    required
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="item.start_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="projectAddStartDateFormat"
                        label="Start Date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="item.start_date"
                      :format="'DD-MM-YYYY'"
                      @input="updateDate"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(item.start_date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    :return-value.sync="item.start_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="projectAddEndDateFormat"
                        label="End Date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="item.end_date"
                      :format="'DD-MM-YYYY'"
                      @input="updateEndDate"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu1 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu1.save(item.end_date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-autocomplete
                    v-model="item.projectStatus"
                    :items="project_status"
                    auto-select-first
                    label="Project Status"
                    item-text="name"
                    item-value="id"
                    deletable-chips
                    class="mt-0 pt-0"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex align-start justify-space-between"
                >
                  <v-switch
                    class="mt-0 pa-0"
                    v-model="enableEstimatedHours"
                    label="Enable Estimated Hours"
                    color="success"
                    inset
                  ></v-switch>
                  <span class="span_text" style="color: #878dab"
                    >Hours : {{ total_hours || "00" }}</span
                  >
                </v-col>
                <template v-if="enableEstimatedHours">
                  <v-col
                    cols="12 mb-4"
                    v-for="(h, index) in hours_list"
                    :key="index"
                  >
                    <v-row no-gutters align="center" style="gap: 10px">
                      <v-text-field
                        v-model="hours_list[index].name"
                        dense
                        hide-details
                        outlined
                        style="flex: 1"
                      ></v-text-field>
                      <v-text-field
                        v-model="hours_list[index].hours"
                        dense
                        label="Hours"
                        type="number"
                        outlined
                        hide-details
                        style="flex: 0.5"
                      ></v-text-field>
                      <v-btn
                        icon
                        @click="addField"
                        v-if="hours_list.length - 1 === index"
                      >
                        <v-icon color="primary" size="24"
                          >mdi mdi-plus-circle-outline</v-icon
                        >
                      </v-btn>
                      <v-btn v-else icon @click="removeField(index)">
                        <TrashIcon :size="24" />
                      </v-btn>
                    </v-row>
                  </v-col>
                </template>
              </v-row>
            </v-form>
          </v-card>
          <v-card flat v-if="item.step == 2">
            <v-form ref="step2Form" v-model="step2Valid" lazy-validation>
              <p class="mb-2">Please choose a color</p>
              <v-swatches
                swatch-size="30"
                v-model="item.project_color_hex"
                inline
                shapes="circles"
              >
              </v-swatches>
              <p>
                <input
                  v-model="item.project_color_hex"
                  type="color"
                  class="fallback-input"
                  :style="{ backgroundColor: item.project_color_hex }"
                />
              </p>
              <div
                class="mt-10 secondary--text"
                style="font-size: 13px; font-weight: 500"
              >
                Team Member
              </div>
              <v-autocomplete
                v-model="item.team"
                :items="notSelectedEmployees"
                dense
                chips
                :height="'50'"
                class="mt-2 team-member-select"
                label="Select Member"
                item-text="name"
                item-value="id"
                multiple
                outlined
                @input="clearInput"
                :search-input.sync="searchInput"
                hide-details
              >
                <template v-slot:selection="" />
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object' && true">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
              <div class="d-flex flex-wrap pa-2 selected-team-member-container">
                <div
                  v-for="selectedEmployee in selectedEmployees"
                  :key="selectedEmployee.id"
                  class="mr-4 mb-1 d-inline-"
                >
                  {{ selectedEmployee.name }}
                  <v-icon
                    @click="deleteSelectedEmp(selectedEmployee.id)"
                    size="16"
                    >mdi-close-circle</v-icon
                  >
                </div>
                <!-- <p v-if="!selectedEmployees.length" class="text-center full-width mb-0">
                  No Employees Selected
                </p> -->
              </div>
            </v-form>
          </v-card>
          <v-card flat v-if="item.step == 3">
            <v-form ref="step3Form" v-model="step3Valid" lazy-validation>
              <!-- Acive status div -->
              <v-card flat>
                <p class="mb-3 black--text">Active Statuses</p>
                <div
                  v-for="(activeStatus, i) in activeStatuses"
                  v-bind:key="i"
                  class="status-div"
                >
                  <input
                    :ref="'c_input_' + i"
                    type="color"
                    v-model="activeStatus.color"
                    class="status-color-input subtitle-1"
                  />
                  <v-text-field
                    flat
                    solo
                    :style="{ height: activeStatus.height }"
                    @update:error="onAStatusError($event, i)"
                    class="status-input"
                    :color="activeStatus.color"
                    required
                    :rules="statusRule"
                    placeholder="STATUS NAME"
                    dense
                    v-model="activeStatus.tag"
                  >
                    <v-btn
                      @click="chooseColor(i)"
                      slot="prepend-inner"
                      :color="activeStatus.color"
                      icon
                    >
                      <v-icon size="18">mdi-circle</v-icon>
                    </v-btn>
                    <v-btn
                      @click="deleteStatus(i)"
                      slot="append"
                      :color="activeStatus.color"
                      icon
                    >
                      <TrashIcon />
                    </v-btn>
                  </v-text-field>
                </div>
                <v-btn
                  class="mt-2 mb-3 btn-sm"
                  outlined
                  @click="addActiveStatus"
                  color="primary"
                  small
                >
                  <v-icon class="mr-2">mdi-plus</v-icon>Add Status
                </v-btn>
              </v-card>
              <!-- Acive status div -->
              <v-card flat class="mt-4">
                <p class="mb-3 black--text">Closed Status</p>
                <div class="status-div">
                  <input
                    ref="close_color_input"
                    type="color"
                    v-model="closedStatus[0].color"
                    class="status-color-input"
                  />
                  <v-text-field
                    flat
                    solo
                    required
                    :rules="statusRule"
                    class="status-input"
                    :color="closedStatus[0].color"
                    dense
                    v-model="closedStatus[0].tag"
                    :style="{ height: closedStatus[0].height }"
                    @update:error="onDStatusError($event)"
                  >
                    <v-btn
                      @click="chooseCloseColor"
                      slot="prepend-inner"
                      :color="closedStatus[0].color"
                      icon
                    >
                      <v-icon size="18">mdi-circle</v-icon>
                    </v-btn></v-text-field
                  >
                </div>
              </v-card>
            </v-form>
          </v-card>
        </template>

        <template>
          <div class="row align-center justify-center mt-5" style="gap: 20px">
            <v-btn
              class="border-radius-6 py-5 text-none white--text mx-0 my-2"
              depressed
              color="secondary"
              @click="createProjectClose"
              v-if="item.step == 1"
            >
              Close
            </v-btn>
            <v-btn
              class="border-radius-6 py-5"
              depressed
              color="primary"
              @click.prevent="createProjectStep1"
              v-if="item.step == 1"
            >
              Continue
            </v-btn>
            <v-btn
              class="border-radius-6 py-5 text-none white--text mx-0 my-2"
              depressed
              color="secondary"
              @click.prevent="backProjectStep2"
              v-if="item.step == 2"
            >
              <v-icon small>mdi-arrow-left</v-icon>
              BACK
            </v-btn>

            <v-btn
              class="border-radius-6 py-5"
              depressed
              color="primary"
              @click.prevent="createProjectStep2"
              v-if="item.step == 2"
            >
              Continue
            </v-btn>
            <v-btn
              class="border-radius-6 py-5 text-none white--text mx-0 my-2"
              depressed
              color="secondary"
              @click.prevent="backProjectStep3"
              v-if="item.step == 3"
            >
              <v-icon small>mdi-arrow-left</v-icon>
              BACK
            </v-btn>
            <v-btn
              class="border-radius-6 py-5"
              depressed
              color="primary"
              @click="submitProject"
              v-if="item.step == 3"
            >
              Create
            </v-btn>
          </div>
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapMutations, mapState } from "vuex";
import VSwatches from "vue-swatches";
import debounce from "v-debounce";
import Avatar from "../common/basic/Avatar.vue";

import Dialog from "../common/basic/Dialog.vue";

import StepperIndicator from "../common/basic/StepperIndicator.vue";
import TrashIcon from "../../assets/icons/TrashIcon.vue";
import EyeIcon from "@/assets/icons/EyeIcon.vue";
import PencilIcon from "@/assets/icons/PencilIcon.vue";

export default {
  name: "ProjectList",
  data: () => ({
    enableEstimatedHours: false,
    e1: 1,
    search: "",
    searchInput: null,
    editDialog: false,
    dialog: false,
    dialogDelete: false,
    form_type_insert: false,
    form_title: "",
    message: "",
    errors: {},
    project_status: [],
    hours_list: [
      { name: "Estimated Hours (For Development)", hours: 0 },
      { name: "QA Hours (For QA)", hours: 0 },
    ],
    loading: false,
    snackbar: false,
    snackbarError: false,
    text: "hello",
    currentPage: null,
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
      },
      {
        text: "Status",
        align: "start",
        value: "project_status",
      },
      {
        text: "Priority",
        align: "start",
        value: "priority",
      },
      {
        text: "Start Date",
        align: "start",
        value: "start_date",
      },
      {
        text: "Deadline",
        align: "start",
        value: "end_date",
      },
      {
        text: "Assignee",
        align: "start",
        value: "assignee",
      },
      {
        text: "Active / Inactive",
        value: "is_active",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    projects: [],
    selectedTeamMembers: [],
    projectAddStartDateFormat: moment(new Date()).format("DD-MM-YYYY"),
    projectAddEndDateFormat: null,
    item: {
      form_title: "Create project",
      step: 1,
      project_id: "",
      project_color_hex: "#1FBC9C",
      name: "",
      start_date: moment(new Date()).format("YYYY-MM-DD"),
      end_date: null,
      estimated_time_for_developement: 0,
      estimated_time_for_qa: 0,
      team: [],
      is_active: "",
      projectStatus: "",
    },
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    menu1: false,
    menu2: false,
    menu3: false,
    timer: null,
    validation: {
      nameRules: [(v) => !!v || "Name is required"],

      estHourRules: [
        (v) => !!v || "Estimated hours for development is required",
        (v) => Number.isInteger(Number(v)) || "The value must be number",
      ],
      estHourQARules: [
        (v) => !!v || "Estimated hours for QA is required",
        (v) => Number.isInteger(Number(v)) || "The value must be number",
      ],
    },
    statusRule: [(v) => !!v || "Status is required"],
    step1Valid: true,
    step2Valid: true,
    step3Valid: true,

    friends: [],
    activeStatuses: [
      {
        tag: "OPEN",
        color: "#d3d3d3",
        height: "38px",
      },
      {
        tag: "IN PROGRESS",
        color: "#4194f6",
        height: "38px",
      },
      {
        tag: "REVIEW",
        color: "#a875ff",
        height: "38px",
      },
    ],
    total_hours: 0,
    closedStatus: [
      {
        tag: "CLOSED",
        color: "#6bc950",
        height: "38px",
      },
    ],
  }),
  components: {
    VSwatches,
    Avatar,
    StepperIndicator,
    TrashIcon,
    EyeIcon,
    PencilIcon,
    Dialog,
  },

  mounted() {
    this.getItemFromLocal();
    this.get_project_list();
    this.getEmployeeList();
    this.getProjectStatus();
    this.search = this.$store.state.search.search;
  },
  computed: {
    ...mapState("projects", ["syncProjects"]),
    ...mapState("employees", ["employeesList", "cachesEmpData"]),

    filteredItems() {
      const queryStatus = this.$route.query.status;

      const status = queryStatus ? parseInt(queryStatus) : null;
      if (status) {
        return this.projects.filter((item) => item.project_status === status);
      }

      return this.projects;
    },
    formattedDate() {
      if (this.item.start_date) {
        return moment(this.item.start_date).format("DD-MM-YYYY");
      }
      return "";
    },

    dialogTitle() {
      if (this.item.step === 1) {
        return "Create project";
      } else if (this.item.step === 2) {
        return "Project color and team";
      } else if (this.item.step === 3) {
        return "Task statuses";
      } else {
        return null;
      }
    },

    notSelectedEmployees() {
      return this.employeesList.filter((emp) => {
        return !this.item.team.includes(emp.id);
      });
    },
    selectedEmployees() {
      return this.employeesList.filter((emp) => {
        return this.item.team.includes(emp.id);
      });
    },
    completedProjects() {
      return this.projects.filter((p) => p.project_status === 3).length ?? 0;
    },
    inProgressProjects() {
      return this.projects.filter((p) => p.project_status === 2).length ?? 0;
    },
    onHoldProjects() {
      return this.projects.filter((p) => p.project_status === 4).length ?? 0;
    },
    cancelProjects() {
      return this.projects.filter((p) => p.project_status === 5).length ?? 0;
    },
  },
  watch: {
    syncProjects() {
      if (this.syncProjects) {
        this.syncWithClickUp();
      }
    },
    search() {
      this.setSearch({
        search: this.search,
      });
    },
    currentPage(value) {
      localStorage.setItem("project_page_no", value);
    },
    hours_list: {
      handler(newValue) {
        this.total_hours = newValue.reduce(
          (total, item) => total + parseInt(item.hours || 0),
          0
        );
      },
      deep: true,
    },
    // Watch for route changes to trigger re-filtering
    "$route.query.status": function () {
      this.filteredItems;
    },
  },
  methods: {
    ...mapMutations("search", ["setSearch"]),
    ...mapMutations("projects", ["setSyncProjects", "setProjectData"]),
    ...mapMutations("employees", ["setEmployees", "isCachesEmpData"]),

    updateDate(date) {
      this.projectAddStartDateFormat = moment(date, "YYYY-MM-DD").format(
        "DD-MM-YYYY"
      );
      this.item.start_date = date;
    },

    updateEndDate(date) {
      this.projectAddEndDateFormat = moment(date, "YYYY-MM-DD").format(
        "DD-MM-YYYY"
      );
      this.item.end_date = date;
    },
    getStatus(start_Date, end_Date) {
      const startDate = moment(start_Date);
      const endDate = moment(end_Date);
      const currentDate = moment();

      const totalDays = endDate.diff(startDate, "days");
      const remainingDays = endDate.diff(currentDate, "days");
      const progress = ((totalDays - remainingDays) / totalDays) * 100;

      if (progress >= 90) {
        return { color: "#EB4848", name: "High" };
      } else if (progress >= 50) {
        return { color: "#A384FA", name: "Medium" };
      } else if (progress >= 10) {
        return { color: "#58D18A", name: "Low" };
      } else {
        return { color: "#878DAB", name: "Clear" };
      }
    },
    getStatusColor(status) {
      switch (status) {
        case "Created":
          return { chip: "#f1edfe", text: "#A384FA" };
        case "In Progress":
          return { chip: "#FFF5E1", text: "#FEA246" };
        case "Completed":
          return { chip: "#E9FFF2 ", text: "#58D18A" };
        case "On Hold":
          return { chip: "#feecef", text: "#F3425F" };
        case "Archive":
          return { chip: "#feecef", text: "#1f9bd5" };
        default:
          return { chip: "#A384FA ", text: "white" };
      }
    },
    clearSearch() {
      this.search = "";
    },
    submitProject() {
      if (this.$refs.step3Form.validate()) {
        this.item.start_date = moment(
          this.projectAddStartDateFormat,
          "DD-MM-YYYY"
        ).format("YYYY-MM-DD");
        this.item.end_date = moment(
          this.projectAddEndDateFormat,
          "DD-MM-YYYY"
        ).format("YYYY-MM-DD");
        var data = {
          ...this.item,
          activeStatuses: this.activeStatuses,
          closedStatus: this.closedStatus,
          hours: this.hours_list.filter((h) => h.name && h.hours),
        };
        this.$axios.post("/project", data).then((res) => {
          let _self = this;
          let response = res.data;

          if (!response.success) {
            _self.errors = {};
            if (response.errors) {
              for (let i = 0; i < response.errors.length; i++) {
                _self.errors[response.errors[i].field] = data.errors[i].message;
              }
            } else {
              _self.snackbarError = true;
              _self.message = response.message;
            }
            _self.dialog = true;
          } else {
            _self.projects.push(response.project);
            this.createProjectClose();
          }
        });
      }
    },
    editProject() {
      if (this.item.project_id) {
        const hours = this.hours_list.filter((h) => h.name && h.hours);

        var data = {
          project_color_hex: this.item.project_color_hex,
          name: this.item.name,
          start_date: moment(
            this.projectAddStartDateFormat,
            "DD-MM-YYYY"
          ).format("YYYY-MM-DD"),
          end_date: moment(this.projectAddEndDateFormat, "DD-MM-YYYY").format(
            "YYYY-MM-DD"
          ),
          estimated_time_for_developement:
            this.item.estimated_time_for_developement,
          estimated_time_for_qa: this.item.estimated_time_for_qa,
          project_status: this.item.projectStatus,
          hours: hours,
          // ...this.item,
          // activeStatuses: this.activeStatuses,
          // closedStatus: this.closedStatus,
        };
        this.$axios
          .put("/project/" + this.item.project_id, data)
          .then((res) => {
            let _self = this;
            let response = res.data;

            if (!response.success) {
              _self.errors = {};
              if (response.errors) {
                for (let i = 0; i < response.errors.length; i++) {
                  _self.errors[response.errors[i].field] =
                    data.errors[i].message;
                }
              } else {
                _self.snackbarError = true;
                _self.message = response.message;
              }
            } else {
              this.get_project_list();
              this.editProjectClose();
            }
          });
      }
    },
    editProjectStatus(item, status) {
      if (item.project_id) {
        var data = {
          project_status: status.id,
        };
        this.$axios
          .put("/project_status/" + item.project_id, data)
          .then((res) => {
            let _self = this;
            let response = res.data;

            if (!response.success) {
              _self.errors = {};
              if (response.errors) {
                for (let i = 0; i < response.errors.length; i++) {
                  _self.errors[response.errors[i].field] =
                    data.errors[i].message;
                }
              } else {
                _self.snackbarError = true;
                _self.message = response.message;
              }
            } else {
              this.get_project_list();
              this.editProjectClose();
            }
          });
      }
    },

    addField() {
      this.hours_list.push({ name: "", hours: 0 });
    },
    removeField(index) {
      this.hours_list.splice(index, 1);
    },

    getProjectStatus() {
      this.$axios.get("project_statuses").then((res) => {
        // let _self = this;
        let response = res.data;

        if (response.success) {
          // project_status
          this.project_status = response.statuses;
        }
      });
    },
    deleteSelectedEmp(selectedEmpId) {
      this.item.team = this.item.team.filter((item) => item != selectedEmpId);
    },

    onAStatusError(e, i) {
      // if (e) {
      //   this.activeStatuses[i].height = "unset";
      // } else {
      this.activeStatuses[i].height = "38px";
      // }
    },
    onDStatusError(e) {
      if (e) {
        this.closedStatus[0].height = "unset";
      } else {
        this.closedStatus[0].height = "38px";
      }
    },
    chooseColor(i) {
      this.$refs["c_input_" + i][0].click();
    },
    chooseCloseColor() {
      this.$refs["close_color_input"].click();
    },
    deleteStatus(i) {
      this.activeStatuses.splice(i, 1);
    },
    addActiveStatus() {
      this.activeStatuses.push({
        tag: "",
        color: "#ff0000",
        height: "38px",
      });
    },
    remove(item) {
      const index = this.item.team.indexOf(item.id);
      if (index >= 0) this.item.team.splice(index, 1);
    },
    getItemFromLocal() {
      let localItem = localStorage.getItem("new_project");
      if (localItem) {
        this.item = JSON.parse(localItem);
      }
    },
    createProjectStep1() {
      if (this.$refs.step1Form.validate()) {
        // Storing step 1 data in localstorage
        this.item.step = 2;
        this.item.form_title = "Project color and team";
        localStorage.setItem("new_project", JSON.stringify(this.item));
      }
    },
    createProjectStep2() {
      if (this.$refs.step2Form.validate()) {
        // Storing step 2 data in localstorage
        this.item.step = 3;
        this.item.form_title = "Task statuses";
        localStorage.setItem("new_project", JSON.stringify(this.item));
      }
    },
    backProjectStep2() {
      if (this.$refs.step2Form.validate()) {
        this.item.step = 1;
        this.item.form_title = "Create project";
        localStorage.setItem("new_project", JSON.stringify(this.item));
      }
    },
    backProjectStep3() {
      if (this.$refs.step3Form.validate()) {
        this.item.step = 2;
        this.item.form_title = "Project color and team";
        localStorage.setItem("new_project", JSON.stringify(this.item));
      }
    },
    createProjectClose() {
      this.dialog = false;
      this.editDialog = false;
      this.item = {
        form_title: "Create project",
        step: 1,
        project_id: "",
        project_color_hex: "#1FBC9C",
        name: "",
        start_date: moment(new Date()).format("YYYY-MM-DD"),
        end_date: null,
        estimated_time_for_developement: 0,
        estimated_time_for_qa: 0,
        team: [],
      };
      this.form_title = "Create Project";
      localStorage.removeItem("new_project");
      // this.$refs.step1Form.resetValidation();
    },
    editProjectClose() {
      this.editDialog = false;
      this.projectAddStartDateFormat = moment(new Date()).format("DD-MM-YYYY");

      this.item = {
        form_title: "Create project",
        step: 1,
        project_id: "",
        project_color_hex: "#1FBC9C",
        name: "",
        start_date: moment(new Date()).format("YYYY-MM-DD"),
        end_date: null,
        estimated_time_for_developement: 0,
        estimated_time_for_qa: 0,
        team: [],
      };

      if (this.$refs.step1Form) {
        this.$refs.step1Form.resetValidation();
      }
      this.errors = {};

      localStorage.removeItem("new_project");
    },
    syncWithClickUp() {
      let _self = this;
      _self.loading = true;
      _self.$axios.post("syncClickUpSpaces").then((res) => {
        let data = res.data;

        _self.loading = false;

        if (data.success) {
          _self.get_project_list();
          _self.setSyncProjects({
            syncProjects: false,
          });
        } else {
          _self.snackbarError = true;
          _self.message = data.message;
        }
      });
    },
    convertDateFormat(date) {
      if (date) {
        return moment(date).isValid()
          ? moment(date).format("Do MMMM YYYY")
          : "";
      }
    },
    redirect(project_id) {
      this.$router
        .push({ path: "/projects/" + project_id + "/board" })
        .catch(() => {});
    },
    redirectToArchive() {
      this.$router.push({ path: "/archiveProjects" }).catch(() => {});
    },
    viewKanban(id) {
      this.$router
        .push({ name: "kanban2", params: { listId: id } })
        .catch(() => {});
    },
    submit() {
      if (this.form_type_insert) {
        this.save();
      } else {
        this.update();
      }
    },
    get_project_list() {
      this.loading = true;
      this.$axios.get("project").then((res) => {
        this.projects = res.data.projects;
        this.setProjectData(res.data.projects);
        let defaultPage = localStorage.getItem("project_page_no");
        this.currentPage = Number(defaultPage ? defaultPage : 1);
        this.loading = false;
      });
    },

    getEmployeeList() {
      if (this.cachesEmpData) {
        return;
      }
      this.$axios.get("employee").then((res) => {
        this.setEmployees(res.data.employees);
        this.isCachesEmpData(true);
      });
    },

    editItem(item) {
      this.form_type_insert = false;
      this.editedIndex = this.projects.indexOf(item);
      this.item.project_id = item.project_id ? item.project_id : "";
      this.item.name = item.name;
      this.item.project_color_hex = item.project_color_hex;
      this.item.estimated_time_for_developement =
        item.estimated_time_for_developement;
      this.item.estimated_time_for_qa = item.estimated_time_for_qa;
      this.item.start_date = item.start_date
        ? moment(item.start_date).format("YYYY-MM-DD")
        : null;
      this.projectAddStartDateFormat = item.start_date
        ? moment(item.start_date).format("DD-MM-YYYY")
        : null;
      this.item.end_date = moment(item.end_date).isValid()
        ? moment(item.end_date).format("YYYY-MM-DD")
        : null;
      this.projectAddEndDateFormat = moment(item.end_date).isValid()
        ? moment(item.end_date).format("DD-MM-YYYY")
        : null;
      this.item.projectStatus = item.project_status;
      this.item.is_active = item.is_active;
      this.editDialog = true;
      this.errors = {};
      this.hours_list = JSON.parse(item.hours)?.length
        ? JSON.parse(item.hours)
        : this.hours_list;
      this.enableEstimatedHours = JSON.parse(item.hours)?.length ? true : false;
    },

    deleteItem(item) {
      this.editedIndex = this.projects.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let _self = this;
      this.$axios
        .delete("/project/" + this.editedItem.project_id)
        .then((res) => {
          if (!res.data.success) {
            _self.snackbarError = true;
            _self.message = res.data.message;
          }
          _self.editedItem = {};
        });
      this.projects.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.editedItem = {};
      this.editedIndex = -1;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.editedItem = {};
      this.editedIndex = -1;
    },

    save() {
      this.$axios.post("/project", this.item).then((res) => {
        let _self = this;
        let data = res.data;

        if (!data.success) {
          _self.errors = {};
          if (data.errors) {
            for (let i = 0; i < data.errors.length; i++) {
              _self.errors[data.errors[i].field] = data.errors[i].message;
            }
          } else {
            _self.snackbarError = true;
            _self.message = data.message;
          }
          _self.dialog = true;
        } else {
          _self.projects.push(data.project);
          _self.dialog = false;
          _self.item = {};
        }
      });
    },
    update() {
      this.loading = true;

      this.$axios
        .put("/project/" + this.item.project_id, this.item)
        .then((res) => {
          let _self = this;
          let data = res.data;

          if (data.success == false) {
            _self.errors = {};
            if (data.errors) {
              for (let i = 0; i < data.errors.length; i++) {
                _self.errors[data.errors[i].field] = data.errors[i].message;
              }
            } else {
              _self.snackbarError = true;
              _self.message = data.message;
            }
          } else {
            _self.projects[_self.editedIndex] = _self.item;
            _self.item = {};
            _self.editedIndex = -1;
            _self.get_project_list();
          }
          _self.loading = false;
        });
    },
    enableProject(projectId) {
      this.loading = true;
      this.$axios
        .put("/enable_project/" + projectId, this.item.is_active)
        .then((res) => {
          let _self = this;
          let data = res.data;

          if (data.success == false) {
            _self.errors = {};
            _self.snackbarError = true;
            _self.message = data.message;
          } else {
            _self.snackbar = true;
            _self.message = data.message;
          }
          _self.loading = false;
        });
    },
    gotoProjectView(item) {
      this.$router
        .push({ path: "projects/" + item.project_id })
        .catch(() => {});
    },
    openAddProjectDialog() {
      this.dialog = !this.dialog;
      this.form_title = "Create Project";
      this.form_type_insert = true;
      if (this.project_status.length) {
        this.item.projectStatus = this.project_status[0].id || null;
      }
    },
    clearInput() {
      this.searchInput = null;
    },
  },
  directives: {
    debounce,
  },
};
</script>

<style>
.team-member-select {
  color: var(--primary-font-color) !important;
}

.team-member-select fieldset {
  border: 3px solid var(--border-color) !important;
  border-radius: 6px 6px 0px 0px !important;
}

.team-member-select .v-label {
  top: 16px !important;
}

.team-member-select .v-select__slot {
  padding: 6px 0px !important;
}

.selected-team-member-container {
  border-top: none;
  border: 2px solid var(--border-color) !important;
  background: #f0f4f8;
  border-radius: 0px 0px 6px 6px !important;
}
.asssignee-container {
  position: relative !important;
  display: inline;
}

.asssignee-container:hover > .remove-icon {
  display: inherit !important;
}

.asssignee-container:hover > .remove-icon-in-dialog {
  display: inherit !important;
}
.team-name-avatars {
  transition: all 150ms;
  margin-right: -10px;
}

.team-name-avatars:hover {
  margin-top: -5px;
  z-index: 1;
}

.team-name-avatars.avatar-icon,
.team-name-avatars .avatar-icon {
  border-color: white !important;
  border: 1px solid;
}
.span_header {
  font-size: 14px;
  color: #323338;
  font-weight: 400;
}
.chip_text .v-chip__content {
  color: #f0f4f8 !important;
}
.span_text {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 14px !important;
  color: #878dab !important;
}
</style>
